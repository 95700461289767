import {
  getUtmParamsFromLocalStorage,
  getUtmParamsFromUrl,
  saveUtmParamsToLocalStorage,
} from "./utmUtils";

const handleUTM = () => {
  if (typeof window === "undefined") {
    return;
  }

  const urlParams = getUtmParamsFromUrl();
  const localStorageParams = getUtmParamsFromLocalStorage();

  if (
    urlParams.utm_source ||
    urlParams.utm_medium ||
    urlParams.utm_campaign ||
    urlParams.utm_term ||
    urlParams.utm_content
  ) {
    saveUtmParamsToLocalStorage(urlParams);
  }
};

handleUTM();
