const LOCAL_STORAGE_KEY = "utmParams";

export const getUtmParamsFromUrl = () => {
  if (typeof window === "undefined") {
    return {};
  }

  const urlParams = new URLSearchParams(window.location.search);
  return {
    utm_source: urlParams.get("utm_source") || "",
    utm_medium: urlParams.get("utm_medium") || "",
    utm_campaign: urlParams.get("utm_campaign") || "",
    utm_term: urlParams.get("utm_term") || "",
    utm_content: urlParams.get("utm_content") || "",
  };
};

export const getUtmParamsFromLocalStorage = () => {
  try {
    const storedParams = localStorage.getItem(LOCAL_STORAGE_KEY);
    return storedParams ? JSON.parse(storedParams) : {};
  } catch (error) {
    console.error("Error parsing localStorage UTM params", error);
    return {};
  }
};

export const saveUtmParamsToLocalStorage = (params) => {
  try {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(params));
  } catch (error) {
    console.error("Error saving UTM params to localStorage", error);
  }
};
