import "styles/globals.scss";

import {
  GoogleTagNoscript,
  GoogleTagScript,
} from "components/GoogleTagManager";
import { NavigationProgress } from "components/NavigationProgress";
import { Seo } from "components/Seo";
import { fetchTranslations } from "features/translations";
import App from "next/app";
import { fetchGlobalData, GlobalProvider } from "shared/contexts/Global";
import { fetchMenusData, MenusProvider } from "shared/contexts/Menus";
import { TranslationsProvider } from "shared/contexts/Translations";
import { ViewModeProvider } from "shared/contexts/ViewMode";
import { UTMHandler } from "shared/utils/utmUtils/utmParamsSaver";

import { Favicon } from "../components/Favicon";

const Application = ({ Component, globalProps, pageProps }) => {
  const { global, translations, userAgent, menus } = globalProps;

  return (
    <>
      <GoogleTagNoscript googleId={global.googleTagManagerId} />
      <GoogleTagScript googleId={global.googleTagManagerId} />
      <Seo data={pageProps.data.seo} />
      <Favicon url={global.faviconUrl} ext={global.faviconExt} />
      <NavigationProgress />
      <TranslationsProvider value={translations}>
        <ViewModeProvider userAgent={userAgent}>
          <MenusProvider value={menus}>
            <GlobalProvider value={global}>
              <Component {...pageProps} />
            </GlobalProvider>
          </MenusProvider>
        </ViewModeProvider>
      </TranslationsProvider>
    </>
  );
};

// getInitialProps disables automatic static optimization for pages that don't
// have getStaticProps. So article, category and home pages still get SSG.
// Hopefully we can replace this with getStaticProps once this issue is fixed:
// https://github.com/vercel/next.js/discussions/10949
Application.getInitialProps = async (context) => {
  // Calls page's `getInitialProps` and fills `appProps.pageProps`
  const appProps = await App.getInitialProps(context);
  const locale = context.router.locale;

  const translations = await fetchTranslations(locale);
  const global = await fetchGlobalData(locale);
  const menus = await fetchMenusData(locale);

  const userAgent =
    context.ctx.req?.headers["user-agent"] ?? navigator?.userAgent;

  return {
    ...appProps,
    globalProps: {
      userAgent: userAgent,
      global: global.data,
      translations: {
        [locale]: translations,
      },
      menus: menus.data,
    },
  };
};

export default Application;
